<script>
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useDirectory } from '/~rec/composables/directory'

export default {
  name: 'rec-event-status-control',
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { getTodayEvents } = useDirectory()

    return { getTodayEvents }
  },
  computed: {
    canAccept() {
      return this.event?.isDeclined || !this.event.isAccepted
    },
    canDecline() {
      return !this.event.isMeOwner && !this.event?.isDeclined
    },
  },
  methods: {
    async onAccept() {
      await this.event.accept()
      this.getTodayEvents()
    },
    async onDecline() {
      await this.event.decline()
      this.getTodayEvents()
    },
  },
}
</script>

<template>
  <div class="mx-auto flex justify-center">
    <!-- Accept -->
    <base-button
      v-if="canAccept"
      icon="rec/check-circle-active"
      class="mr-2.5"
      size="lg"
      @click.stop.prevent="onAccept"
    />
    <base-icon v-else size="lg" class="mr-2.5" svg="rec/check-circle-green" />

    <!-- Decline -->
    <base-button
      v-if="canDecline"
      size="lg"
      icon="rec/error-circle-active"
      @click.stop.prevent="onDecline"
    />

    <template v-else>
      <base-icon
        v-if="event.isDeclined"
        size="lg"
        class="text-primary"
        svg="rec/error-circle-checked"
      />
      <base-icon v-else size="lg" svg="rec/error-circle-disabled" />
    </template>
  </div>
</template>
